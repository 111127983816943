import { FC } from 'react'
import withTranslateProvider from 'src/hoc/with-translate-provider'
import HeadMetaTags from '~/components/HeadMetaTags'
import LandingPageHireForce from '~/features/landing-page'
import en_lp from '../public/locales/en/landing-page.json'
import vn_lp from '../public/locales/vi/landing-page.json'
import jp_lp from '../public/locales/ja/landing-page.json'
import { useTranslation } from 'react-i18next'
import jsonLd from '../public/schema.json'

const Homepage: FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
      <HeadMetaTags
        title={`${t('lp:meta:title')}`}
        description={`${t('lp:meta:description')}`}
      />
      <LandingPageHireForce />
    </>
  )
}

export default withTranslateProvider(Homepage, {
  en: { lp: en_lp },
  vi: { lp: vn_lp },
  ja: { lp: jp_lp }
})
