import Image from 'next/image'

const StarBadge = ({ label }: { label: string }) => {
  return (
    <div className="z-0 flex items-center gap-2 w-fit border text-gray-900 bg-white font-normal border-gray-200 rounded-full tablet:pr-5 tablet:pl-4 tablet:py-1.5 text-base tablet:text-lg  py-[6px] pr-4 pl-3 tracking-normal">
      <Image
        src="/img/feature/lp/star.svg"
        width={19}
        height={20}
        alt="star"
        quality={100}
        className="w-19 h-auto"
      />
      {label}
    </div>
  )
}
export default StarBadge
