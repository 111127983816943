import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FCC } from '~/core/@types/global'

const FeatureSection: FCC<{ className?: string }> = ({ className = '' }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState<number | null>(0)

  const toggleAccordion = (index: number) => {
    setExpanded(expanded === index ? null : index)
  }

  return (
    <div className={`overflow-hidden ${className}`}>
      <div className="relative ">
        <main className="relative flex flex-col justify-center bg-slate-50 overflow-hidden">
          <div className="w-full mx-auto pt-[56px] desktop:pt-[88px]">
            <div className="divide-y divide-slate-200 border-t border-b">
              {[...Array(3).keys()].map((index) => (
                <div key={index} className="flex flex-col items-center py-2">
                  <button
                    id={`faqs-title-0${index + 1}`}
                    type="button"
                    className="flex w-full justify-center text-[31px] leading-[40px] desktop:text-[43px] desktop:leading-[52px] py-2 text-gray-600"
                    onClick={() => toggleAccordion(index)}
                    aria-expanded={expanded === index}
                    aria-controls={`faqs-text-0${index + 1}`}>
                    {t(`lp:feature_section:item_title_${index + 1}`)}
                  </button>

                  <div
                    id={`faqs-text-0${index + 1}`}
                    role="region"
                    aria-labelledby={`faqs-title-0${index + 1}`}
                    className={`max-w-2xl mt-1 grid text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${
                      expanded === index
                        ? 'grid-rows-[1fr] opacity-100'
                        : 'grid-rows-[0fr] opacity-0'
                    }`}>
                    <div className="overflow-hidden text-center">
                      <span>
                        {t(`lp:feature_section:item_des_${index + 1}`)}
                      </span>
                      <img
                        src={`/img/feature/lp/feat_${index + 1}.png`}
                        alt="all_platform"
                        loading="lazy"
                        className="mt-10 mb-8"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default FeatureSection
