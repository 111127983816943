import { FC } from 'react'
import LPContainer from './LPContainer'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '~/core/ui/Button'
import Link from 'next/link'
import { URL_REGISTER_APP } from '~/core/constants/url'
import StarBadge from './components/StarBadge'
import Slider from 'react-slick'
import Image from 'next/image'

const SimpleEffectiveSection: FC = () => {
  const { t, i18n } = useTranslation()

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    focusOnSelect: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots top-[14rem] tablet:top-[45rem]'
  }

  return (
    <div className="relative overflow-hidden bg-[#F6F9FE] min-h-[714px] tablet:min-h-[1390px]">
      {/* Slider */}
      <div className="relative z-[0] top-[430px] p-4 tablet:p-0 tablet:top-[570px] tablet:mx-auto max-w-[1136px] ">
        <div className=" rounded-lg tablet:rounded-2xl border border-[#DCDBE1] shadow-[0_0_0_3px_#EBEFF6,0_0_0_7px_#F6F9FE,0_0_0_8px_#E3E6EC] tablet:shadow-[0_0_0_8px_#EBEFF6,0_0_0_15px_#F6F9FE,0_0_0_16px_#E3E6EC] tablet:min-h-[704px]">
          <Slider {...settings} className="max-h-[704px]">
            {[1, 2, 3, 4].map((index) => (
              <div
                key={`screenshot-image-${index}`}
                className="animate-fadeInUp !border-0 !outline-0 rounded-2xl">
                <img
                  width={1134}
                  height={702}
                  src={`/img/feature/lp/screenshot_${index}.png`}
                  alt={`screenshot ${index}`}
                  className="rounded-2xl"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* background */}
      <div className="z-10 hidden absolute tablet:flex items-center justify-center w-full desktop:bottom-[52rem] tablet:bottom-[810px]">
        <Image
          src="/img/feature/lp/lines_on_hero.svg"
          width={1440}
          height={645}
          alt="lines_on_hero"
          priority
        />
      </div>
      <Image
        src="/img/feature/lp/lines_on_hero_left.svg"
        width={160}
        height={200}
        alt="lines_on_hero_left"
        priority
        className="absolute bottom-[271px] -left-0 -top-[6px] tablet:hidden"
      />
      <Image
        src="/img/feature/lp/lines_on_hero_right.svg"
        width={160}
        height={200}
        alt="lines_on_hero_right"
        priority
        className="absolute bottom-[271px] -right-0 -top-[22px] tablet:hidden"
      />

      {/* logos mobile */}
      <Image
        src="/img/feature/lp/illus_1.png"
        width={125}
        height={0}
        alt="Illus 1 image"
        className="absolute h-auto tablet:hidden -left-20 top-[20rem]"
      />

      {/* logos */}
      <div className="z-20 hidden tablet:block  absolute left-[calc(50vw_-_53vw)] top-[319px] w-[106vw] mobile:top-[332px] tablet:left-0 tablet:w-[100vw] desktop:left-[calc(50vw_-_617px)] desktop:top-[58px] desktop:w-[1234px]">
        <div className="w-fit animate-[fadeInUp_0.2s_linear_0.35s_both] tablet:pl-[7px] desktop:pl-[54px] desktop:pt-[100px]">
          <Image
            src="/img/feature/lp/illus_1.png"
            width={155}
            height={0}
            alt="Illus 1 image"
            className="h-auto"
          />
        </div>
        <div className="absolute -right-[3.6%] top-[0px] animate-[fadeInUp_0.2s_linear_0.35s_both] tablet:right-0 first-letter:">
          <Image
            src="/img/feature/lp/illus_2.png"
            width={150}
            height={150}
            alt="Illus 2 image"
          />
        </div>
        <div className="absolute hidden animate-[fadeInUp_0.25s_linear_0.3s_both] tablet:right-[214px] tablet:top-[103px] tablet:inline-block desktop:right-[10.51%] desktop:top-[350px]">
          <Image
            src="/img/feature/lp/illus_4.png"
            width={96}
            height={96}
            alt="Illus 4 image"
            style={{
              width: 'clamp(44px, 14vw - 64px, 96px)'
            }}
          />
        </div>
      </div>

      <div className="z-30 absolute top-16 tablet:top-24 inset-x-1/8 w-full flex flex-col justify-center">
        <LPContainer>
          <div className="flex justify-center mb-3 tablet:mb-5">
            <StarBadge
              label={t('lp:simple_section:take_recruiting_strategy')}
            />
          </div>
          <h1 className="mb-4 animate-fadeInUp text-center font-normal leading-[52px] text-gray-900 text-[43px] tablet:mb-[20] tablet:text-[72px] tablet:leading-[72px]">
            <Trans i18nKey={'lp:simple_section:title'}>
              <p className="text-primary-400" />
              <p />
            </Trans>
          </h1>
          <div className="mx-auto max-w-[343px] animate-fadeInUp text-center text-lg tablet:max-w-[654px] text-gray-900 tablet:text-[19px]">
            {t('lp:simple_section:description')}
          </div>
          <Link href={URL_REGISTER_APP} locale={i18n?.language} target="_blank">
            <Button
              size="xl"
              label={`${t('lp:try_for_free')}`}
              className="mt-6 mx-auto animate-fadeInUp tablet:mt-[40px]"
              fontWeight="font-normal"
              iconMenus="ArrowUpRight"
              icon="trailing"
            />
          </Link>
        </LPContainer>
      </div>
    </div>
  )
}

export default SimpleEffectiveSection
