import { useTranslation } from 'react-i18next'

const InfiniteMarquee = () => {
  const { t } = useTranslation()

  return (
    <div
      x-data="{}"
      x-init="$nextTick(() => {
                        let ul = $refs.marquees;
                        ul.insertAdjacentHTML('afterend', ul.outerHTML);
                        ul.nextSibling.setAttribute('aria-hidden', 'true');
                    })"
      className="w-full inline-flex flex-nowrap overflow-hidden border border-[#E9ECF1]">
      <ul
        x-ref="marquees"
        className="flex items-center justify-center [&_li]:mx-2 [&_img]:max-w-none animate-marquee">
        {Array(8)
          .fill([...Array(5).keys()])
          .flat()
          .map((marquee, index) => {
            return (
              <li
                key={`marquee-${index}`}
                className="flex flex-row py-2 w-max tablet:py-3 justify-center items-center gap-4 tablet:gap-8  tablet:pr-2">
                <span className="mx-2 text-base tablet:text-lg">
                  {t(`lp:all_platform_section:marquee_${marquee + 1}`)}
                </span>
                <img
                  src={'/img/feature/lp/sparkle.png'}
                  alt="sparkle"
                  className="w-2 h-2"
                />
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default InfiniteMarquee
