import { FC } from 'react'
// import ClientsSection from '~/components/LandingPage/ClientsSection'
// import ExploreSection from '~/components/LandingPage/ExploreSection'
import JourneySection from '~/components/LandingPage/JourneySection'
import TeamSection from '~/components/LandingPage/TeamSection'
import SimpleEffectiveSection from '~/components/LandingPage/SimpleEffectiveSection'
import LayoutLandingPage from '~/components/Layout/LayoutLandingPage'
import LazyLoad from 'react-lazyload'
import AllPlatformSection from '~/components/LandingPage/AllPlatformSection'
import FeatureSection from '~/components/LandingPage/FeatureSection'
import InnovativeSection from '~/components/LandingPage/InnovativeSection'

const LandingPageHireForce: FC = () => {
  return (
    <>
      <LayoutLandingPage>
        <SimpleEffectiveSection />
        <LazyLoad height={300} once>
          <AllPlatformSection />
        </LazyLoad>
        <LazyLoad height={300} once>
          <FeatureSection />
        </LazyLoad>
        {/* <LazyLoad height={300} once>
        <ReviewSection />
      </LazyLoad> */}
        {/* <LazyLoad height={300} once>
        <ExploreSection />
      </LazyLoad> */}
        <LazyLoad height={300} once>
          <InnovativeSection />
        </LazyLoad>
        <LazyLoad height={300} once>
          <TeamSection />
        </LazyLoad>
        <LazyLoad height={300} once>
          <JourneySection />
        </LazyLoad>
      </LayoutLandingPage>
    </>
  )
}

export default LandingPageHireForce
